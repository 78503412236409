import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane } from "reactstrap";
import { Icon } from "../components/Component";
import classnames from "classnames";
import defaultBgImage from "../images/applogos/black_theme/background-img.jpg";
import defaultWhiteBgImage from "../images/applogos/white_theme/new_bg_white.png";
import Wallpaper_1 from "../images/applogos/wallpaper-1.jpg";
import Wallpaper_2 from "../images/applogos/wallpaper-2.jpg";
import Wallpaper_3 from "../images/applogos/wallpaper-3.jpg";
import Dropzone from "react-dropzone";
import { GlobalContext } from "../layout/Index";
import { HexColorPicker } from "react-colorful";
import { useTheme } from "../layout/provider/Theme";

const ImageComponent = ({ modal, toggle }) => {
  const theme = useTheme();
  const {
    setBackground,
    bg,
    setBg,
    color,
    setColor,
    selectedOption,
    setSelectedOption,
    setBackgroundMode,
    backgroundMode,
  } = useContext(GlobalContext);

  const [verticalTab, setVerticalTab] = useState("1");
  const [radio, setRadio] = useState("");
  const [files2, setFiles2] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const [bgImage, setBgImage] = useState(() => {
    const savedImage = localStorage.getItem("bgImage");
    return savedImage ? savedImage : defaultBgImage;
  });

  const backgroundImage = [{ img: defaultBgImage }, { img: Wallpaper_1 }, { img: Wallpaper_2 }, { img: Wallpaper_3 }];

  const handleRadioChange = (image, radioId, index) => {
    setRadio(radioId);
    setBg(image.img);
    setBgImage(image.img);
    setBackground(image.img);
    setBackgroundMode("image");
    setSelectedImageIndex(index);
    localStorage.setItem("bg_img_type", "predefined");
    localStorage.setItem("bg_img_value", image.img);
    localStorage.setItem("selected_radio", radioId);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDropChange = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    try {
      const base64 = await getBase64(file);
      setFiles2([{ ...file, preview: base64 }]);
      setBg(base64);
      setBgImage(base64);
      setBackground(base64);
      setRadio("imageRadioCustom");
      setBackgroundMode("image");
      localStorage.setItem("bg_img_type", "custom");
      localStorage.setItem("bg_img_value", base64);
      localStorage.setItem("selected_radio", "imageRadioCustom");
      localStorage.setItem("custom_image", base64);
    } catch (error) {
      console.error("Error converting file to base64:", error);
    }
  };

  const handleColorChange = (color) => {
    setColor(color);
    setBackgroundMode("color");
    localStorage.setItem("bg_img_type", "color");
    localStorage.setItem("bg_img_value", color);
    localStorage.setItem("selected_radio", "");
  };

  useEffect(() => {
    let img_type = localStorage.getItem("bg_img_type");
    let img_value = localStorage.getItem("bg_img_value");
    let savedRadio = localStorage.getItem("selected_radio");
    let customImage = localStorage.getItem("custom_image");

    if (!img_type || !img_value) {
      setBg(defaultBgImage);
      setBgImage(defaultBgImage);
      setBackground(defaultBgImage);
      setRadio("imageRadio0");
      setBackgroundMode("image");
      localStorage.setItem("bg_img_type", "predefined");
      localStorage.setItem("bg_img_value", defaultBgImage);
    } else {
      switch (img_type) {
        case "color":
          setColor(img_value);
          setBackgroundMode("color");
          setRadio("");
          break;
        case "custom":
          setBg(customImage);
          setBgImage(customImage);
          setBackground(customImage);
          setRadio("imageRadioCustom");
          setBackgroundMode("image");
          setFiles2([{ preview: customImage }]);
          break;
        case "predefined":
          setBg(img_value);
          setBgImage(img_value);
          setBackground(img_value);
          setBackgroundMode("image");
          setRadio(savedRadio || "imageRadio0");
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" className="background-image-modal">
      <ModalHeader
        className="justify-between"
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Main Background Image
      </ModalHeader>
      <ModalBody>
        <section>
          <div className="row">
            <div className="col-lg-8">
              <div className="main-bg-image" style={{ background: backgroundMode === "color" ? color : "none" }}>
                {backgroundMode === "image" && (
                  <img
                    src={bgImage}
                    alt=""
                    className={`position-relative ${
                      selectedOption === "center" ? "bg-contain" : selectedOption === "fill" ? "bg-cover" : ""
                    }`}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <Row className="g-3 image-selector">
                {backgroundImage.map((image, index) => {
                  const radioId = `imageRadio${index}`;
                  return (
                    <Col md="6" className="col-6" key={index}>
                      <div className="preview-block">
                        <div
                          className={classnames("custom-control", "custom-radio", "image-control", {
                            active: selectedImageIndex === index,
                          })}
                        >
                          <input
                            type="radio"
                            className="custom-control-input"
                            id={radioId}
                            checked={radio === radioId}
                            onChange={() => handleRadioChange(image, radioId, index)}
                          />
                          <label className="custom-control-label" htmlFor={radioId}>
                            <img src={image.img} alt={`Background ${index + 2}`} />
                          </label>
                        </div>
                      </div>
                    </Col>
                  );
                })}

                {files2.length > 0 && (
                  <Col md="6" className="col-6">
                    <div className="preview-block">
                      <div className="custom-control custom-radio image-control">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="imageRadioCustom"
                          checked={radio === "imageRadioCustom"}
                          onChange={() => handleRadioChange({ img: files2[0].preview }, "imageRadioCustom")}
                        />
                        <label className="custom-control-label" htmlFor="imageRadioCustom">
                          <img src={files2[0].preview} alt="Custom Background" />
                        </label>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="col-12 mt-4">
              <Row className="g-gs background-component">
                <Col md={4}>
                  <ul className="nav link-list-menu round m-0 p-0">
                    <li className="image-tabs">
                      <a
                        href="#tab"
                        className={classnames({ active: verticalTab === "1" }, "tab-menu")}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalTab("1");
                        }}
                      >
                        <Icon name="img-fill" /> <span>Background Image</span>
                      </a>
                    </li>
                    <li className="image-tabs">
                      <a
                        href="#tab"
                        className={classnames({ active: verticalTab === "2" }, "tab-menu")}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setVerticalTab("2");
                        }}
                      >
                        <Icon name="view-x1" /> <span>Colors</span>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={8}>
                  <TabContent activeTab={verticalTab}>
                    <TabPane tabId="1">
                      <p>Background Image / Video</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)} maxFiles={1}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                                    <input {...getInputProps()} />
                                    {files2.length === 0 && (
                                      <div className="dz-message">
                                        <span className="dz-message-text">Drag and drop file</span>
                                        <span className="dz-message-or">or</span>
                                        <Button color="primary">SELECT</Button>
                                      </div>
                                    )}
                                    {files2.map((file) => (
                                      <div
                                        key={file.name}
                                        className="dz-preview dz-processing dz-image-preview dz-error dz-complete m-0"
                                      >
                                        <div className="dz-image">
                                          <img src={file.preview} alt="preview" />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="mb-0">Choose a fit.</p>
                              <div>
                                <select
                                  name="fitOption"
                                  id="fitOption"
                                  className="w-100px select-fit-image"
                                  value={selectedOption}
                                  onChange={handleChange}
                                >
                                  <option value="fit">Fit</option>
                                  <option value="center">Center</option>
                                  <option value="fill">Fill</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="">
                          <div className="bg-colorpicker">
                            <HexColorPicker color={color} onChange={handleColorChange} />
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </ModalBody>
    </Modal>
  );
};

export default ImageComponent;
