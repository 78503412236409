import React from "react";

export default function Star() {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.1549 1.71852C4.70512 0.60365 6.29488 0.603651 6.8451 1.71852L7.35519 2.75207C7.57368 3.19478 7.99603 3.50163 8.4846 3.57263L9.62518 3.73836C10.8555 3.91714 11.3468 5.4291 10.4565 6.2969L9.63117 7.10141C9.27764 7.44601 9.11631 7.94251 9.19977 8.4291L9.39461 9.56508C9.60477 10.7904 8.31862 11.7249 7.21818 11.1463L6.19801 10.61C5.76102 10.3803 5.23897 10.3803 4.80199 10.61L3.78182 11.1463C2.68138 11.7249 1.39523 10.7904 1.60539 9.56508L1.80023 8.4291C1.88369 7.94251 1.72236 7.44601 1.36883 7.10141L0.543501 6.2969C-0.346775 5.4291 0.144489 3.91714 1.37482 3.73836L2.5154 3.57263C3.00397 3.50164 3.42632 3.19478 3.64481 2.75207L4.1549 1.71852Z"
        fill="#787878"
      />
    </svg>
  );
}
