import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";

import styles from "../applications.module.scss";

import { useTheme } from "@mui/material/styles";
import { Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import { Col, Icon, Row } from "../../../components/Component";

import ImageWithPlaceholder from "../../../components/common/ui/images/image-with-placeholder";
import Star from "../../../components/common/ui/svg/star";
import { getCookie } from "../../../utils/Utils";

export function AppCardSkeleton() {
  const isDark = useTheme().palette.mode === "dark";

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = getCookie("authToken");
    setAuthenticated(isAuthenticated);
  }, []);

  const authToken = getCookie("authToken");

  return (
    <Card className={`p-3 w-100 ${styles["app-card"]} ${isDark ? styles["dark"] : ""}`}>
      <Row className="align-items-center">
        <Col sm={2} md={3} className="text-center">
          <Skeleton variant="rectangular" width={50} height={50} />
        </Col>
        <Col sm={10} md={9}>
          <h5 className="mb-1">
            <Skeleton width={"65%"} />
          </h5>
          <p className="mb-0 text-muted">
            <Skeleton width={"45%"} />
          </p>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <Stack direction={"row"} alignItems={"center"} gap={0.2}>
              <span>
                <Skeleton width={"1.5rem"} />
              </span>
              <Icon name="star-fill" />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={0.2} justifyContent={"flex-end"} width={"100%"}>
              {" "}
              <Skeleton width={"25%"} />
            </Stack>
          </Stack>
        </Col>
      </Row>
    </Card>
  );
}

export default function AppCard({ item, isDark }) {
  const smMatches = useMediaQuery("(min-width: 20rem)");
  const lgMatches = useMediaQuery("(min-width: 64rem)");

  const url = isDark
    ? `${process.env.REACT_APP_API_URL}${item.image}`
    : `${process.env.REACT_APP_API_URL}${item.dark_image}`;

  return (
    <div>
      <Card
        className={`p-3 w-100 ${styles["app-card"]} ${isDark ? styles["dark"] : ""}`}
        style={{
          cursor: "pointer",
          ...(isDark ? {} : { backgroundColor: "hsla(0, 0%, 99%, 1)" }),
        }}
        onClick={() => {
          console.log(getCookie("authToken"));
          window.location.href =
            item.title == "Talk" && getCookie("authToken")
              ? `${item.url}token-login/${getCookie("authToken")}`
              : item.title == "Wallet" && getCookie("authToken")
              ? `${process.env.REACT_APP_PROJECT_OLD_LINK_NAME}token_login/${getCookie("authToken")}/wallet-dashboard`
              : item.url;
        }}
      >
        <Row className="align-items-center">
          <Col lg={lgMatches ? 3 : smMatches ? 2 : 3} className="text-center">
            <ImageWithPlaceholder width={50} height={50} style={{ objectFit: "contain" }} alt={item.title} src={url} />
          </Col>
          <Col sm={10} md={9}>
            <h6 style={{ fontWeight: 500, fontSize: "14px", marginBottom: "8px" }}>{item.title}</h6>
            <Typography fontSize={"8px"} variant="subtitle2" className="text-muted">
              {item.category}
            </Typography>
            <div className="d-flex align-items-center justify-content-between" style={{ marginTop: "9.5px" }}>
              <Stack direction={"row"} alignItems={"center"} gap={0.4}>
                <span className="theme-mode" style={{ fontSize: "10px" }}>
                  {item.rating}
                </span>
                <Star />
              </Stack>
              <span className="ms-3 fw-medium text-grey" style={{ fontSize: "9px" }}>
                {item.app_category}
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
