import { configureStore } from "@reduxjs/toolkit";

//^ slices
import muiReducer from "./slices/mui-slice";

const store = configureStore({
  reducer: {
    mui: muiReducer,
  },
});

export default store;
