import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Spinner,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import SimpleBar from "simplebar-react";

//^ stylesheets
import styles from "../../../../assets/scss/_themes-vars.module.scss";
import styleVar from "../../../../assets/scss/_themes-vars.module.scss";
// import "./notification.scss";

//^ http request
import { getAllNotificationHandler, setNotificationCountHandler } from "../../../../utils/api_helper";
//^ icons

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { useThemeUpdate } from "../../../provider/Theme";

import { shortenString } from "../../../../utils/Utils";
import { TooltipComponent } from "../../../../components/Component";
import NotificationSkeletonLoader from "./NotificationSkeletonLoader";
// import EventNotification from "./EventNotification";
// import { getCategoryValueByLabel } from "../../../../components/partials/calender/CalenderData";

function NotificationItem({ title, time, isSender, link, updatedAt, recent, index, notification }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const EventToggle = () => setIsOpen(!isOpen);
  const [eventChildData, setEventChildData] = useState();
  const [eventTheme, setEventTheme] = useState();

  const handleModuleNotifications = (notification) => {
    switch (notification.module) {
      case "1":
        console.log(`Component Not Available!...`);
        break;
      case "2":
        console.log(`Component Not Available!...`);
        break;
      case "3":
        console.log(`Component Not Available!...`);
        break;
      case "4":
        console.log(`Component Not Available!...`);
        break;
      case "5":
        console.log(`Component Not Available!...`);
        break;
      case "6":
        setIsOpen(true);
        console.log(notification.reference_id);
        setNotificationCountHandler({ notification_id: notification.reference_id, status: 1 });
        break;
      case "7":
        console.log(`Component Not Available!...`);
        break;
      case "8":
        console.log(`Component Not Available!...`);
        break;
      case "9":
        console.log(`Component Not Available!...`);
        break;
      case "10":
        console.log(`Component Not Available!...`);
        break;
      case "11":
        console.log(`Component Not Available!...`);
        break;
      case "12":
        console.log(`Component Not Available!...`);
        break;
      default:
        console.log(`Component Not Available!...`);
        break;
    }
  };

  // const handleChildData = (data) => {
  //   setEventChildData(data);
  //   if (data) {
  //     const te = getCategoryValueByLabel(data.category);
  //     setEventTheme(te);
  //   }
  // };

  return (
    <>
      <Modal isOpen={isOpen} toggle={EventToggle}>
        <ModalHeader className={eventTheme && eventTheme} toggle={() => EventToggle()}>
          {eventChildData && eventChildData.event_title}
        </ModalHeader>
        <ModalBody>
          {/* <EventNotification onSendChildData={handleChildData} eventId={notification.reference_id} /> */}
        </ModalBody>
      </Modal>
      <li
        className="p-4 web-app"
        onClick={() => {
          // setNotificationCountHandler({ notification_id: notification.reference_id, status: 1 });
          // navigate(`${link}`);
          handleModuleNotifications(notification);
        }}
      >
        <div className="d-flex justify-content-between align-items-start w-100">
          <div className="d-flex align-items-center gap-2">
            <div className="nk-notification-icon">
              <Icon
                name={isSender ? "curve-down-right" : "curve-down-left"}
                className={[`icon-circle ${isSender ? "bg-primary-dim" : "bg-success-dim"}`]}
              />
            </div>
            <div className="nk-notification-content">
              <div
                className="nk-notification-text"
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {title.length > 27 ? (
                  <TooltipComponent
                    type="text"
                    content={shortenString(title, 27)}
                    direction="top"
                    id={`notification-${index}`}
                    text={title}
                  />
                ) : (
                  title
                )}
              </div>
              <div className="nk-notification-time">{time}</div>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "600" }}>{moment(updatedAt).format("h:MM A")}</div>
          </div>
        </div>
      </li>
    </>
  );
}

const Notification = () => {
  const themeUpdate = useThemeUpdate();

  const [open, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({ notifications: [] });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  // Fetch notifications
  const {
    data: allNotificationData,
    isLoading: allNotificationIsLoading,
    isRefetching: allNotificationIsRefetching,
    isError: allNotificationIsError,
    error: allNotificationError,
    refetch: allNotificationRefetch,
  } = useQuery({
    queryKey: ["get-all-notification", limit],
    queryFn: async () => getAllNotificationHandler({ limit: limit }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (allNotificationIsError) {
      console.log(allNotificationError);
    }
  }, [allNotificationIsError, allNotificationError]);

  //^ Reading all the unread notifications
  // const {
  //   mutate: readUnreadNotificationsMutate,
  //   reset: readUnreadNotificationsReset,
  //   error: readUnreadNotificationsError,
  //   isError: readUnreadNotificationsIsError,
  // } = useMutation({
  //   mutationKey: ["set-notification-count-for-unread-msg"],
  //   mutationFn: setNotificationCountHandler,
  //   onSuccess: () => {
  //     readUnreadNotificationsReset();
  //     allNotificationRefetch();
  //   },
  // });

  // useEffect(() => {
  //   if (readUnreadNotificationsIsError) {
  //     console.log(readUnreadNotificationsError?.info);
  //   }
  // }, [readUnreadNotificationsError, readUnreadNotificationsIsError]);

  // Mark all notifications as read mutation
  const { isPending: markAllReadIsPending, mutate: markAllReadMutate } = useMutation({
    mutationKey: ["mark-all-as-read"],
    mutationFn: setNotificationCountHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          toast.error(data?.message);
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        } else {
          allNotificationRefetch();
          toast.success(data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (!allNotificationIsLoading) {
      if (allNotificationData && allNotificationData.status) {
        const unReadNotifications = Array.isArray(allNotificationData.data?.notifications)
          ? allNotificationData.data.notifications.filter((notification) => notification.seen === "0")
          : [];
        setNotificationCount(unReadNotifications.length || 0);

        const newNotifications = allNotificationData.data.notifications.filter((notification) => {
          return !notificationData.notifications.some(
            (existingNotification) => existingNotification.id === notification.id
          );
        });

        setNotificationData((prevNotification) => ({
          notifications: [...prevNotification.notifications, ...newNotifications],
        }));
      } else if (allNotificationData && allNotificationData.redirect) {
        // window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  useEffect(() => {
    if (!allNotificationIsLoading && allNotificationData) {
      if (notificationData?.status) {
        const notificationsId = allNotificationData?.data?.notifications.map((notification) => notification.id);
        setNotificationIds(notificationsId);
      }
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  // function readUnreadNotificationsHandler() {
  //   readUnreadNotificationsMutate({ notification_id: notificationIds, status: "1" });
  // }
  return (
    <Dropdown
      // onMouseEnter={readUnreadNotificationsHandler}
      isOpen={open}
      toggle={toggle}
      className="user-dropdown"
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-na">
          <Icon name="bell-fill" className="text-white" />
          {notificationCount > 0 && (
            <div color="primary" pill className="countIcon">
              {allNotificationIsLoading ? 0 : notificationCount}
            </div>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head d-flex justify-content-between align-items-center">
          <div>{typeof data.title === "string" ? data.title : "Title Error"}</div>
          <div
            size="small"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (notificationCount <= 0) {
                toast.error("There are no unread messages.");
              } else {
                markAllReadMutate({ notification_id: "0", status: "1" });
              }
            }}
            startIcon={
              markAllReadIsPending ? <Spinner size={"sm"} color="primary" style={{ borderWidth: "1px" }} /> : ""
            }
          >
            Mark All as Read
          </div>
        </div>

        <div className="dropdown-body">
          <div className="nk-notification dropdownMenu">
            {allNotificationIsLoading ? (
              <NotificationSkeletonLoader />
            ) : allNotificationData?.code === 500 ? (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: styleVar.errorMain,
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                {typeof allNotificationData?.message === "string" ? allNotificationData.message : "Error Message"}
              </div>
            ) : notificationData.notifications.length > 0 ? (
              <SimpleBar>
                {notificationData.notifications.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    index={index}
                    title={typeof notification.title === "string" ? notification.title : "Title Error"}
                    time={moment(notification.created_at).format("DD MMM")}
                    isSender={notification.is_sender}
                    link={notification.link}
                    updatedAt={notification.updated_at}
                    recent={notification.recent}
                    notification={notification}
                  />
                ))}
                {!allNotificationIsRefetching && (
                  <div className="dropdown-foot center">
                    <div
                      style={{ cursor: "pointer" }}
                      endIcon={
                        allNotificationIsRefetching ? (
                          <Spinner size="sm" style={{ borderWidth: "1px", color: "inherit" }} />
                        ) : (
                          ""
                        )
                      }
                      onClick={loadMoreNotifications}
                      disabled={allNotificationIsRefetching}
                    >
                      {"View More"}
                    </div>
                  </div>
                )}
              </SimpleBar>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ padding: "1.25rem", color: styles.primaryMain }}>No Notification Found</div>
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
