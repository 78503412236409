import React, { createContext, useEffect, useRef, useState } from "react";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";

import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import { useTheme } from "./provider/Theme";
import "../pages/css/app.css";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { muiAction } from "../store/slices/mui-slice";
import defaultBgImage from "../images/applogos/black_theme/background-img.jpg";
import defaultBgImageWhite from "../images/applogos/white_theme/bg_white.jpeg";

export const GlobalContext = createContext();

const Layout = ({ title, app, ...props }) => {
  const dispatch = useDispatch();
  const muiTheme = useMuiTheme();
  const navigation = useNavigation();
  const isPageLoading = navigation.state === "loading";

  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const { skin } = theme;

  const [bg, setBg] = useState(defaultBgImage);
  const [color, setColor] = useState("#aabbcc");
  const [selectedOption, setSelectedOption] = useState("fit");
  const [backgroundMode, setBackgroundMode] = useState("image");

  // useEffect(() => {
  //   setBg(defaultBgImage);
  // }, [skin]);

  const setBackground = (image) => {
    setBg(image);
    // localStorage.setItem("BackgroundNk-image", image);
    setBackgroundMode("image");
  };

  const backgroundSetImage = {
    backgroundImage: `url(${bg})`,
    backgroundColor: color,
  };

  const [isSearchNewsVisible, setIsSearchNewsVisible] = useState(false);
  const searchNewsRef = useRef(null);

  const onTrendingClick = () => {
    setIsSearchNewsVisible(!isSearchNewsVisible);
  };
  const handleClickOutside = (event) => {
    if (
      searchNewsRef.current &&
      !searchNewsRef.current.contains(event.target) &&
      !event.target.classList.contains("trending-mode")
    ) {
      setIsSearchNewsVisible(false);
    }
  };
  useEffect(() => {
    if (isSearchNewsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchNewsVisible]);

  const [isScrolled, setIsScrolled] = useState(false);
  const [issearchdiv, setIsSearchDiv] = useState(true);

  const handleScroll = () => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody && mainbody.scrollTop > 180) {
      setIsScrolled(true);
      setIsSearchDiv(false);
    } else {
      setIsScrolled(false);
      setIsSearchDiv(true);
    }
  };

  useEffect(() => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody) {
      mainbody.addEventListener("scroll", handleScroll);
      return () => {
        mainbody.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      <ScrollRestoration
        getKey={(location, _matches) => {
          // default behavior
          return location.key;
        }}
      />
      <GlobalContext.Provider
        value={{
          files,
          setFiles,
          setBackground,
          bg,
          setBg,
          color,
          setColor,
          selectedOption,
          setSelectedOption,
          backgroundMode,
          setBackgroundMode,
          isSearchNewsVisible,
          onTrendingClick,
          searchNewsRef,
          isScrolled,
          setIsScrolled,
          issearchdiv,
        }}
      >
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            <AppWrap className={skin === "dark" ? "dark-img" : "light-img"} style={backgroundSetImage}>
              <Header fixed />
              <ToastContainer hideProgressBar autoClose={1200} />
              {isPageLoading && <div>Loading</div>}
              <Outlet />
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GlobalContext.Provider>
    </>
  );
};

export default Layout;
