import React from "react";

const SearchNews = () => {
  return (
    <section className="section-news w-100 pb-8">
      <div className="container">
        <div className="row flex-wrap news-blocks">
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-1.jpg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1">ENTERTAINMENT Harry Styles wins album of the year Grammy; Beyoncé triumphs</p>
                  <small> Sunday, 15 July, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-2.jpg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1">
                    'Kick Russia out of the UN': Group prepares legal challenge as Russia gets set to take UN Security
                    Council presidency{" "}
                  </p>
                  <small> Sunday, 12 July, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-3.jpg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1">ENTERTAINMENT Harry Styles wins album of the year Grammy; Beyoncé triumphs</p>
                  <small> Sunday, 07 July, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-4.jpg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1 ">Francesca’s will close 140 stores, may file for bankruptcy </p>
                  <small> Sunday, 01 July, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-5.jpg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1 ">
                    Princess Cruises cancels weeklong (and longer) US voyages through November 2021
                  </p>
                  <small> Sunday, 29 June, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-2.jpeg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1 ">
                    Starbucks raises wages for its baristas as restaurants brace for minimum wage hike{" "}
                  </p>
                  <small> Sunday, 27 June, 2024</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <div className="card h-100">
              <div className="position-relative">
                <img src="news/news-1.jpeg" alt="" className="w-100" />
                <div className="card-body position-absolute ">
                  <p className="mb-1 ">Stocks move mostly lower even as Nasdaq edges toward record </p>
                  <small> Sunday, 21 June, 2024</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchNews;
