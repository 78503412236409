import { useState, useEffect } from "react";

const useImageLoader = (src) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState("");

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setLoaded(true);
      setCurrentSrc(src);
    };

    img.onerror = () => {
      setError(true);
    };
  }, [src]);

  return { loaded, error, currentSrc };
};

export default useImageLoader;
