import Router from "./route/Index";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

import ThemeProvider from "./layout/provider/Theme";

const App = () => {
  const muiTheme = useSelector((state) => state.mui.theme);

  const theme = createTheme({
    palette: {
      mode: muiTheme,
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
export default App;
