import { QueryClient } from "@tanstack/react-query";
import { getRequest } from "../../utils/Utils";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export async function getAppsHandler({ type }) {
  let url = `api/v1/public/apps`;
  // let url = `dummy-data/banners.json`;

  // const response = await fetch(url);

  if (type) {
    url += `?type=${type}`;
  }

  // return await response.json()
  return await getRequest(url);
}
