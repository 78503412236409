import React from "react";
import Header from "../layout/header/Header";
import { Link } from "react-router-dom";
import { useTheme } from "../layout/provider/Theme";

import { Homelightlogo, d_home_logo } from "../images/applogos";

const PrivacyPolicy = () => {
  const { skin } = useTheme();
  return (
    <>
      <section className={`section-privacy ${window.location.href.includes("/privacy-policy") ? "apps-header" : ""}`}>
        <div className="container-fluid  px-0">
          <Header />
          <div className="container py-3">
            <div className="pb-8">
              <h4 className="text-center">Privacy Policy</h4>
              <p className="text-center">
                We are on a mission to make the web a better place. The following terms, as well as our <br /> Policy
                and Terms of Service, apply to all users.
              </p>
              <div className="">
                <p>
                  Protecting your private information is our priority. This Statement of Privacy applies to
                  silocloud.com, and Silo Secured Data LLC and governs data collection and usage. For the purposes of
                  this Privacy Policy, unless otherwise noted, all references to Silo Secured Data LLC include
                  silocloud.com, SiloCloud, Persona, personaos.com and personapost.com. The SiloCloud website is a
                  secured remote cloud browser that includes security, ownership, and data policies that protect your
                  computer from web exploitation. A cloud browser that keeps the threats away from the user's device and
                  internal networks to keep the business safe. SiloCloud is a cloud computing service site. By using the
                  SiloCloud website, you consent to the data practices described in this statement.
                </p>
                <h5>Collection of your Personal Information</h5>
                <p>
                  In order to better provide you with products and services offered, SiloCloud may collect personally
                  identifiable information, such as your:
                </p>
                <ul style={{ listStyleType: "circle" }}>
                  <li>First and Last Name</li>
                  <li>Mailing Address</li>
                  <li>E-mail Address</li>
                  <li>Phone Number</li>
                </ul>
                <p>
                  If you purchase SiloCloud's products and services, we collect billing and credit card information.
                  This information is used to complete the purchase transaction.
                </p>
                <p>
                  Please keep in mind that if you directly disclose personally identifiable information or personally
                  sensitive data through SiloCloud's public message boards, this information may be collected and used
                  by others. <br />
                  We do not collect any personal information about you unless you voluntarily provide it to us. However,
                  you may be required to provide certain personal information to us when you elect to use certain
                  products or services. These may include (a) registering for an account; (b) entering a sweepstakes or
                  contest sponsored by us or one of our partners; (c) signing up for special offers from selected third
                  parties; (d) sending us an email message; (e) submitting your credit card or other payment information
                  when ordering and purchasing products and services. To wit, we will use your information for, but not
                  limited to, communicating with you in relation to services and/or products you have requested from us.
                  We also may gather additional personal or non-personal information in the future.
                </p>
                <h5>Use of your Personal Information</h5>
                <p>
                  SiloCloud collects and uses your personal information to operate and deliver the services you have
                  requested. <br />
                  SiloCloud may also use your personally identifiable information to inform you of other products or
                  services available from SiloCloud and its affiliates.
                </p>
                <h5>Sharing Information with Third Parties</h5>
                <p>
                  SiloCloud does not sell, rent, or lease its customer lists to third parties. <br />
                  SiloCloud may share data with trusted partners to help perform statistical analysis, send you email or
                  postal mail, provide customer support, or arrange for deliveries. All such third parties are
                  prohibited from using your personal information except to provide these services to SiloCloud, and
                  they are required to maintain the confidentiality of your information. <br />
                  SiloCloud may disclose your personal information, without notice, if required to do so by law or in
                  the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
                  with the legal process served on SiloCloud or the site; (b) protect and defend the rights or property
                  of SiloCloud; and/or (c) act under exigent circumstances to protect the personal safety of users of
                  SiloCloud, or the public.
                </p>
                <h5>Tracking User Behavior</h5>
                <p>
                  SiloCloud may keep track of the websites and pages our users visit within SiloCloud, in order to
                  determine what SiloCloud services are the most popular. This data is used to deliver customized
                  content and advertising within SiloCloud to customers whose behavior indicates that they are
                  interested in a particular subject area.{" "}
                </p>
                <h5>Automatically Collected Information</h5>
                <p>
                  Information about your computer hardware and software may be automatically collected by SiloCloud.
                  This information can include your IP a address, browser type, domain names, access times and referring
                  website addresses. This information is used for the operation of the service, to maintain the quality
                  of the service, and to provide general statistics regarding the use of the SiloCloud website.{" "}
                </p>
                <h5>Use of Cookies</h5>
                <p>
                  The SiloCloud website may use "cookies" to help you personalize your online experience. A cookie is a
                  text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
                  programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be
                  read by a web server in the domain that issued the cookie to you. <br />
                  One of the primary purposes of cookies is to provide a convenience feature to save you time. The
                  purpose of a cookie is to tell the Web server that you have returned to a specific page. For example,
                  if you personalize SiloCloud pages, or register with SiloCloud site or services, a cookie helps
                  SiloCloud to recall your specific information on subsequent visits. This simplifies the process of
                  recording your personal information, such as billing addresses, shipping addresses, and so on. When
                  you return to the same SiloCloud website, the information you previously provided can be retrieved, so
                  you can easily use the SiloCloud features that you customized.
                  <br />
                  You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but
                  you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
                  cookies, you may not be able to fully experience the interactive features of the SiloCloud services or
                  websites you visit.
                </p>
                <h5>Security of your Personal Information</h5>
                <p>
                  SiloCloud secures your personal information from unauthorized access, use, or disclosure. SiloCloud
                  uses the following methods for this purpose: <br />
                  - SSL Protocol <br />
                  When personal information (such as a credit card number) is transmitted to other websites, it is
                  protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. <br />
                  We strive to take appropriate security measures to protect against unauthorized access to or
                  alteration of your personal information. Unfortunately, no data transmission over the Internet or any
                  wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your
                  personal information, you acknowledge that: (a) there are security and privacy limitations inherent to
                  the Internet that are beyond our control; and (b) the security, integrity, and privacy of any and all
                  information and data exchanged between you and us through this Site cannot be guaranteed.
                </p>
                <h5>Right to Deletion</h5>
                <p>
                  Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
                </p>
                <ul style={{ listStyleType: "circle" }}>
                  <li>Delete your personal information from our records; and</li>
                  <li>Direct any service providers to delete your personal information from their records.</li>
                </ul>
                <p>
                  Please note that we may not be able to comply with requests to delete your personal information if it
                  is necessary to:
                </p>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Complete the transaction for which the personal information was collected, fulfill the terms of a
                    written warranty or product recall conducted by federal law, and provide a good or service requested
                    by you, or reasonably anticipated within the context of our ongoing business relationship with you,
                    or otherwise perform a contract between you and us;
                  </li>
                  <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
                    prosecute those responsible for that activity;
                  </li>
                  <li>Debug to identify and repair errors that impair existing intended functionality;</li>
                  <li>
                    Exercise free speech, ensure the right of another consumer to exercise his or her right of free
                    speech, or exercise another right provided for by law;
                  </li>
                  <li>Comply with the California Electronic Communications Privacy Act;</li>
                  <li>
                    Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                    interest that adheres to all other applicable ethics and privacy laws, when our deletion of the
                    information is likely to render impossible or seriously impair the achievement of such research,
                    provided we have obtained your informed consent;
                  </li>
                  <li>
                    Enable solely internal uses that are reasonably aligned with your expectations based on your
                    relationship with us;
                  </li>
                  <li>Comply with an existing legal obligation; or</li>
                  <li>
                    Otherwise use your personal information, internally, in a lawful manner that is compatible with the
                    context in which you provided the information.
                  </li>
                </ul>
                <h5>Children Under Thirteen</h5>
                <p>
                  SiloCloud does not knowingly collect personally identifiable information from children under the age
                  of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission
                  to use this website.
                </p>
                <h5>E-mail Communications</h5>
                <p>
                  From time to time, SiloCloud may contact you via email for the purpose of providing announcements,
                  promotional offers, alerts, confirmations, surveys, and/or other general communication.
                </p>
                <h5>Changes to this Statement</h5>
                <p>
                  SiloCloud reserves the right to change this Privacy Policy from time to time. We will notify you about
                  significant changes in the way we treat personal information by sending a notice to the primary email
                  address specified in your account, by placing a prominent notice on our website, and/or by updating
                  any privacy information. Your continued use of the website and/or Services available after such
                  modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
                  agreement to abide and be bound by that Policy.
                </p>
                <h5>Contact Information</h5>
                <p>
                  SiloCloud welcomes your questions or comments regarding this Statement of Privacy. If you believe that
                  SiloCloud has not adhered to this Statement, please contact SiloCloud at: <br />
                  New Address* 4250 S 76th East Ave E<br /> Tulsa, OK 74145 Email Address: <br />
                  support@silocloud.com <br />
                  Telephone number: <br />
                  9189575570 <br />
                  Effective as of November 03, 2021
                </p>
              </div>
            </div>
          </div>
          <div
            className={`section-footer w-100 position-fixed bottom-0 start-0 z-3 px-3 py-2 ${
              window.location.href.includes("/privacy-policy") ? "apps-footer" : ""
            }`}
          >
            <div className="d-flex justify-content-between container-fluid">
              <Link to="/" className="">
                <img src={skin === "dark" ? d_home_logo : Homelightlogo} alt="" height="44" className="homeicon" />
              </Link>
              <div className="d-flex align-items-center footer-bottom justify-content-center ">
                <Link to="https://silocloud.com/" className="mb-0 fs-12px  opacity-75">
                  About
                </Link>
                <Link to="/privacy-policy" className="mb-0 fs-12px  opacity-75">
                  Privacy
                </Link>
                <Link to="/terms-condition" className="mb-0 fs-12px  opacity-75">
                  Terms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
