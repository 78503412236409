import React, { useState } from "react";
import { Icon, LinkItem, LinkList, UserAvatar } from "../components/Component";
import User from "../images/profile.png";
import User1 from "../images/profile.png";
import User2 from "../images/profile.png";
import User3 from "../images/profile.png";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledDropdown,
} from "reactstrap";

const TalkComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const contacts = [
    {
      id: 1,
      name: "Steven Mark",
      img: User,
      contact: "+14842918926",
      mail: "info@softnio.com",
    },
    {
      id: 2,
      name: "Sam Kamichh",
      img: User1,
      contact: "+14844578275",
      mail: "dora@softnio.com",
    },
    {
      id: 3,
      name: "Joe Doe",
      img: User2,
      contact: "+19293091043",
      mail: "jess@softnio.com",
    },
    {
      id: 4,
      name: "Matt Henry",
      img: User3,
      contact: "+19293091513",
      mail: "flowers@vitzo.com",
    },
    {
      id: 5,
      name: "Ricardo Salazar",
      img: User1,
      theme: "primary",
      contact: "+14842918926",
      mail: "salazar@softnio.com",
    },
  ];

  const toggleoffcanvas = () => {
    setIsOpen(!isOpen);
  };

  const searchcontact = (e) => {
    let text = e.target.value;
    setSearchQuery(text);
  };

  const filteredContacts = contacts.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <>
      <div className="d-flex align-items-center footer-icons justify-content-center justify-content-lg-end mb-2">
        <span>
          <Icon name="menu" className="icon" onClick={toggleoffcanvas} />
        </span>
        <span>
          <Icon name="video-fill" className="icon" />
        </span>
        <span>
          <Icon name="mic" className="icon" />
        </span>
        <span className="end-call">
          <Icon name="call-fill" className="icon red-icon me-0 text-white" />
        </span>
      </div>
      <div className="connect-list">
        <Offcanvas direction="end" isOpen={isOpen} className="connect-list-canvas" toggle={toggleoffcanvas}>
          <OffcanvasHeader toggle={toggleoffcanvas}>Contact List</OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <div>
              <div className="form-control-wrap mx-2 header-search">
                <div className="form-icon form-icon-left">
                  <Icon name="search" />
                </div>
                <input
                  className="form-control"
                  type="text"
                  id="contactsearch"
                  placeholder="Search"
                  style={{ borderRadius: "24px" }}
                  onChange={searchcontact}
                />
              </div>
              <ul className="nk-ibx-contact">
                {filteredContacts.map((item) => (
                  <li key={item.id}>
                    <a href="#item" onClick={(ev) => ev.preventDefault()}>
                      <div className="user-card align-items-center g-2">
                        <img src={item.img} alt={item.name} height={40} />
                        <div className="user-info">
                          <span className="">{item.name}</span>
                          <span className="sub-text">{item.contact ? item.contact : item.mail}</span>
                        </div>
                      </div>
                    </a>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        href="#item"
                        onClick={(ev) => ev.preventDefault()}
                        className="dropdown-toggle"
                      >
                        <Icon name="more-v"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <LinkList opt className="link-list-opt no-bdr">
                          {/* <LinkItem link={"/user-details-regular/1"}>View Profile</LinkItem> */}
                          <li>
                            <DropdownItem tag="a" href="#item">
                              <span>Send SMS</span>
                            </DropdownItem>
                          </li>
                          {/* <LinkItem link={"/app-chat"}>Start Chat</LinkItem> */}
                        </LinkList>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                ))}
              </ul>
            </div>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </>
  );
};

export default TalkComponent;
