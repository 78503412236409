import React from "react";
import { Box, Skeleton } from "@mui/material";
import useImageLoader from "../../../../../hooks/user-image-loader";

const ImageWithPlaceholder = ({ src, alt, style, width, height }) => {
  const { loaded } = useImageLoader(src);

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      {!loaded && (
        <Skeleton
          variant="rectangular"
          sx={{
            width: width || "100%",
            height: height || "100%",
            ...style,
          }}
        />
      )}
      {loaded && (
        <img
          loading="lazy"
          src={src}
          alt={alt}
          style={{
            width: width || "100%",
            height: height || "100%",
            transition: "opacity 0.5s ease",
            opacity: loaded ? 1 : 0,
            ...style,
          }}
        />
      )}
    </Box>
  );
};

export default ImageWithPlaceholder;
