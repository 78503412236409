import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { GlobalContext } from "../Index";

function AppWrap({ className, ...props }) {
  const { bg, color, selectedOption, backgroundMode } = useContext(GlobalContext);

  const backgroundStyles = backgroundMode === "image" ? { backgroundImage: `url(${bg})` } : { backgroundColor: color };

  const compClass = classNames("nk-wrap", className, {
    "bg-contain": selectedOption === "center",
    "bg-fill": selectedOption === "fill",
    "bg-cover": selectedOption === "fit",
  });

  const mergedStyles = {
    height: "100vh",
    ...backgroundStyles,
  };

  return (
    <div className={`overflow-auto h-100vh position-relative ${compClass}`} style={mergedStyles}>
      {props.children}
    </div>
  );
}

export default AppWrap;
