import { ScrollRestoration } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import { useTheme as muiTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTheme } from "../provider/Theme";

import { muiAction } from "../../store/slices/mui-slice";

import color from "../../assets/scss/_themes-vars.module.scss";

//^ Navigations
import Header from "../header/Header";
import AppFooter from "../footer/app-footer";
import { GlobalContext } from "../Index";

export default function AppLayout() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDark = muiTheme().palette.mode === "dark";

  React.useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty(
        "--bar-color",
        theme.skin === "dark" ? color.darkScrollBarColor : color.lightScrollBarColor
      );
      document.documentElement.style.setProperty(
        "--track-color",
        theme.skin === "dark" ? color.darkScrollBarTrackColor : color.lightScrollbarTrackColor
      );
    }
  }, [theme.skin, dispatch]);

  const [isSearchNewsVisible, setIsSearchNewsVisible] = useState(false);
  const searchNewsRef = useRef(null);

  const onTrendingClick = () => {
    setIsSearchNewsVisible(!isSearchNewsVisible);
  };

  const handleClickOutside = (event) => {
    if (
      searchNewsRef.current &&
      !searchNewsRef.current.contains(event.target) &&
      !event.target.classList.contains("trending-mode")
    ) {
      setIsSearchNewsVisible(false);
    }
  };

  useEffect(() => {
    if (isSearchNewsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchNewsVisible]);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody && mainbody.scrollTop > 180) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody) {
      mainbody.addEventListener("scroll", handleScroll);
      return () => {
        mainbody.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <>
      <ScrollRestoration
        getKey={(location, _matches) => {
          return location.key;
        }}
      />
      <GlobalContext.Provider
        value={{
          isSearchNewsVisible,
          onTrendingClick,
          isScrolled,
          searchNewsRef,
        }}
      >
        <Header fixed />

        <div style={isDark ? { paddingTop: "4rem" } : { paddingTop: "4rem", background: "white", minHeight: "100vh" }}>
          <Outlet />
        </div>
        <AppFooter />
        <ToastContainer hideProgressBar autoClose={1200} />
      </GlobalContext.Provider>
    </>
  );
}
