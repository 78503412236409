import React, { useEffect, useRef, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// layouts
import Layout, { GlobalContext } from "../layout/Index";
import AppLayout from "../layout/app-layout";

// pages
import SearchPage from "../pages/SearchPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";

// applications pages
import Applications, { loader as applicationsLoader } from "../pages/applications";

const Pages = () => {
  const router = createBrowserRouter([
    {
      path: `${process.env.PUBLIC_URL}`,
      element: <Layout />,
      children: [{ index: true, element: <SearchPage /> }],
    },
    {
      path: `${process.env.PUBLIC_URL}/apps`,
      element: <AppLayout />,
      children: [{ index: true, element: <Applications />, loader: applicationsLoader }],
    },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "terms-condition", element: <TermsCondition /> },
  ]);

  const [isSearchNewsVisible, setIsSearchNewsVisible] = useState(false);
  const searchNewsRef = useRef(null);

  const onTrendingClick = () => {
    setIsSearchNewsVisible(!isSearchNewsVisible);
  };

  const handleClickOutside = (event) => {
    if (
      searchNewsRef.current &&
      !searchNewsRef.current.contains(event.target) &&
      !event.target.classList.contains("trending-mode")
    ) {
      setIsSearchNewsVisible(false);
    }
  };

  useEffect(() => {
    if (isSearchNewsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchNewsVisible]);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody && mainbody.scrollTop > 180) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    const mainbody = document.getElementsByClassName("nk-wrap")[0];
    if (mainbody) {
      mainbody.addEventListener("scroll", handleScroll);
      return () => {
        mainbody.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        isSearchNewsVisible,
        onTrendingClick,
        isScrolled,
        searchNewsRef,
      }}
    >
      <RouterProvider router={router} />
    </GlobalContext.Provider>
  );
};
export default Pages;
