import React, { Fragment, Suspense, useEffect } from "react";
import Slider from "react-slick";
import { Badge, Col } from "reactstrap";

import styles from "./applications.module.scss";

import { useTheme } from "@mui/material/styles";
import { Grid, Skeleton } from "@mui/material";

//^ http request
import { getAppsHandler, queryClient } from "../../http/get-apis";

//^ components
import { SlickArrowLeft, SlickArrowRight } from "../../components/partials/slick/SlickComponents";
import { useQuery } from "@tanstack/react-query";
import { responseErrorHandler } from "../../utils/Utils";
import Head from "../../layout/head/Head";
import ImageWithPlaceholder from "../../components/common/ui/images/image-with-placeholder";

import AppCard, { AppCardSkeleton } from "./app-card";
import { Await, defer, useLoaderData } from "react-router";

function BannerSkeleton() {
  return (
    <div className={`${styles["banner-section"]}`}>
      <div style={{ position: "relative" }}>
        <Skeleton variant="rectangular" height="360px" width="100%" sx={{ borderRadius: "1.5rem" }} />
      </div>
    </div>
  );
}

function BannerSection(props) {
  return (
    <div className={`${styles["banner-section"]}`}>
      <div
        style={{
          position: "relative",
        }}
      >
        <Slider {...props.settings2}>
          {props.bannerData?.data?.apps?.All?.map((item) => (
            <Col key={item.id} className="px-3">
              <ImageWithPlaceholder
                height="360px"
                style={{
                  borderRadius: "1.5rem",
                  objectFit: "cover",
                }}
                alt={item.title}
                src={`${process.env.REACT_APP_API_URL}${item.image}`}
              />
            </Col>
          ))}
        </Slider>
      </div>
    </div>
  );
}

function AppsSectionSkeleton() {
  return (
    <div className={`${styles["app-section"]}`}>
      <div className={`${styles.tabs} px-3`}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} variant="rectangular" sx={{ borderRadius: "1rem" }} width={100} height={"30px"} />
        ))}
      </div>
      <div className={`${styles.apps} px-3`}>
        {Array.from({ length: 10 }).map((_, index) => (
          <div key={index}>
            <AppCardSkeleton />
          </div>
        ))}
      </div>
    </div>
  );
}

function AppsSection({ setCategoryHandler, ...props }) {
  const isDark = useTheme().palette.mode === "dark";

  return props.appsIsLoading ? (
    <AppsSectionSkeleton />
  ) : (
    <div className={`${styles["app-section"]}`}>
      <div className={`${styles.tabs} px-3`}>
        {props.state.categories?.map((item, index) => {
          return (
            <Badge
              className="fw-900"
              pill
              color={`${
                props.state.showCategory === item && props.state.categoryIdx === index ? "primary" : "outline-dark"
              } ${styles["chip"]} ${props.state.showCategory === item ? styles["active"] : ""} ${
                isDark ? styles["dark"] : ""
              }`}
              key={index}
              onClick={() => setCategoryHandler(item, index)}
            >
              {item}
            </Badge>
          );
        })}
      </div>
      {/* <div className={`${styles.apps} px-3`}> */}
      <Grid container spacing={"1px"} justifyContent={"center"} alignItems={"center"} marginTop={"2.5rem"}>
        {props.state.apps?.map((item, index) => (
          <Grid item xs={12} md={6} lg={3}>
            <Fragment key={index}>
              <AppCard isDark={props.isDark} item={item} />
            </Fragment>
          </Grid>
        ))}
      </Grid>
      {/* </div> */}
    </div>
  );
}

export default function Applications() {
  const { bannersResponse, appsResponse } = useLoaderData();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const initialState = {
    categories: [],
    showCategory: "All",
    categoryIdx: -1,
    apps: [],
    originalApps: [],
  };
  function appReducerHandler(state, action) {
    switch (action.type) {
      case "SET_CATEGORIES":
        return { ...state, categories: action.payload };

      case "SET_SHOW_CATEGORY":
        const apps = state.originalApps[action.payload.category] || state.originalApps?.All || [];

        return { ...state, showCategory: action.payload.category, categoryIdx: action.payload.idx, apps: apps };

      case "SET_APPS":
        return { ...state, apps: action.payload, originalApps: action.originalApps };
      default:
        return state;
    }
  }

  const [state, dispatch] = React.useReducer(appReducerHandler, initialState);

  const {
    data: bannerData,
    isLoading: bannerIsLoading,
    isError: bannerIsError,
    error: bannerError,
  } = useQuery({
    queryKey: ["get-app-banners"],
    queryFn: () => getAppsHandler({ type: "banner" }),
  });

  const { data: appsData, isLoading: appsIsLoading } = useQuery({
    queryKey: ["get-all-apps"],
    queryFn: () => getAppsHandler({ type: "app" }),
  });

  useEffect(() => {
    if (!appsIsLoading) {
      const categories = appsData?.data?.tabs || [];
      dispatch({
        type: "SET_APPS",
        payload: appsData?.data?.apps?.[state.showCategory] || [],
        originalApps: appsData?.data?.apps || {},
      });
      dispatch({ type: "SET_CATEGORIES", payload: categories });
    }
    // eslint-disable-next-line
  }, [appsData, appsIsLoading]);

  useEffect(() => {
    if (bannerIsError) {
      responseErrorHandler(true, bannerIsError);
    }
  }, [bannerError, bannerIsError]);

  const settings2 = {
    className: "slider-init plan-list",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    // infinite: true,
    autoPlay: true,
    autoPlaySpeed: 100,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    slide: "li",
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  function setCategoryHandler(category, index) {
    dispatch({ type: "SET_SHOW_CATEGORY", payload: { category, idx: index } });
  }

  return (
    <>
      <Head title={`Applications`} />
      <div className="pb-8">
        {" "}
        <Suspense fallback={<BannerSkeleton />}>
          <Await resolve={bannerIsLoading ? bannersResponse : bannerData}>
            {(_) => <BannerSection bannerData={bannerData} settings2={settings2} />}
          </Await>
        </Suspense>
        <Suspense fallback={<AppsSectionSkeleton />}>
          <Await resolve={appsIsLoading ? appsResponse : appsData}>
            {(_) => (
              <AppsSection
                setCategoryHandler={setCategoryHandler}
                isDark={isDark}
                state={state}
                bannerIsLoading={bannerIsLoading}
                appsIsLoading={appsIsLoading}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </>
  );
}

export async function loader() {
  const bannersResponse = queryClient.fetchQuery({
    queryKey: ["get-app-banners"],
    queryFn: () => getAppsHandler({ type: "banner" }),
  });
  const appsResponse = queryClient.fetchQuery({
    queryKey: ["get-all-apps"],
    queryFn: () => getAppsHandler({ type: "app" }),
  });

  return defer({ bannersResponse, appsResponse });
}
